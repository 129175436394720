import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
// import SampleForm from '../components/Form'
// import moneydialogue from '../img/money-dialogue.svg'
// import swish from '../img/swish.png'
// import swishw from '../img/swish-white.png'
// import empben from '../img/employer-benefits-icon.png'
import whitebranch from '../img/large-branch-white.png'
// import leaf from '../img/03-leaf.svg'
// import plant from '../img/001-plant.svg'
// import plants from '../img/02-plant-1.svg'
// import plantsoil from '../img/05-plant-2.svg'

class ResourcePage extends React.Component {
  render() {
    const siteTitle = 'Thanks for Subscribing'
    const siteDescription = this.props.data.site.siteMetadata.description

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />

        <div class="service-section5">
          <div class="container">
            <div class="col-md-12">
              <div class="bg text-center">
                <img alt="whitebranch" src={whitebranch} />
              </div>
              <h1 class="section-h2 text-white">
                <em>Welcome to our Resource Page</em>
              </h1>
              <p className="service-p text-white">
                This page will be updated soon.
              </p>
              <Link class="bttn float-center ty-btn" to="/">
                Back to Homepage
              </Link>
            </div>
          </div>
        </div>

        {/* <SampleForm
            form_name="Newsletter Form"
            form_id="5bcd42f86b63453b251972bc"
            form_classname="form-newsletter"
          >
          <div className="form-row">
          <div className="col-12 mb-3">
            <div className="form-group">
              <input
                className="form-control"
                type="email"
                name="Email Address"
                id="email_address"
              />
              <label htmlFor="email_address">
                Enter Your Email Address
              </label>
            </div>
            <small className="text-dark form-text mt-2">
                We will never share your email with anyone else.
              </small>
          </div>
          <div className="col-12 mb-3">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                name="Name"
                id="full_name"
              />
              <label htmlFor="full_name">
                Enter Your Name
              </label>
            </div>
          </div>
          <div className="col-12">
            
              <div className="webriq-recaptcha" />
            
          </div>

          <div className="col-12">
            <div className="form-group">
              <button
                className="btn btn-primary hvr-shadow"
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
          </SampleForm> */}
      </Layout>
    )
  }
}
export default ResourcePage

export const ResourcePageQuery = graphql`
  query ResourcePageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
